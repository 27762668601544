/* eslint-disable max-len */

import React from "react";

import { allowCookies } from "../utils/cookies";

const popups = [
  {
    props: {
      id: "cookie-popup",
      title: "Verwendung von Cookies",
      buttons: [
        {
          text: "Einverstanden",
          hidePermanent: true,
          onClick: allowCookies,
        },
        {
          text: "Ablehnen",
          hidePermanent: true,
        },
      ],
    },
    body: (
      <React.Fragment>
        Wir verwenden Cookies und den localStorage ihres Browsers, um die Nutzererfahrung auf unserer Website zu
        verbessern.
      </React.Fragment>
    ),
  },
  {
    expires: new Date("April 23, 2025 00:00:00"),
    props: {
      id: "easter-2025-popup",
      title: "Zur Information unserer Kunden",
      buttons: [
        {
          text: "Nicht mehr anzeigen",
          hidePermanent: true
        },
      ],
    },
    body: (
      <React.Fragment>
        <p>In diesem Jahr bleibt die Praxis am <b>Ostersamstag</b> geschlossen.</p>
        <p>Am Dienstag, 22.04.2025, sind wir von 17.00 bis 20.00 Uhr telefonisch erreichbar.</p>
        <p>Bitte vereinbaren Sie für den Besuch der Praxis einen Termin.</p>
        <br />
        <p>Außerhalb der Sprechzeiten wenden Sie sich in dringenden Notfällen bitte an die Kleintierklinik Frankfurt Bockenheim unter Tel.-Nr.: 069 / 97 07 49 55.</p>
        <p>Wir wünschen unseren Kunden und Patienten <b>schöne Feiertage</b>.</p>
        <br/>
        <p>Ihre Tierärztin Dr. Sabine Ruppert</p>
        <p>April 2025</p>
      </React.Fragment>
    ),
  },
];

export default popups;
